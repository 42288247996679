import React from 'react'
import slideImage from '../../static/images/censimento_triennale_closeup.jpg'

const SLIDES = [
  {
    backgroundColor: 'red',
    img: slideImage,
    content: 'It is a long established fact that a reader will be distracted by.',
  },
  {
    backgroundColor: 'yellow',
    img: slideImage,
    content: 'It is a long established fact that a reader will be distracted by.',
  },
  {
    backgroundColor: 'purple',
    img: slideImage,
    content: 'It is a long established fact that a reader will be distracted by.',
  },
  {
    backgroundColor: 'green',
    img: slideImage,
    content: 'It is a long established fact that a reader will be distracted by.',
  },
]

export default class ScrollHijackingPage extends React.Component {
  scrollHijackRef = React.createRef()

  state = {
    isSticky: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = e => {
    const { top } = this.scrollHijackRef.current.getBoundingClientRect()
    if (top < 0) {
      this.setState({ isSticky: true })
    }
    // console.log(document.documentElement.scrollTop, top)
  }

  render() {
    const { isSticky } = this.state

    return (
      <div>
        <div>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
          of classical Latin literature from 45 BC, making it over 2000 years old. Richard
          McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the
          more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the
          cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
          comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
          of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of
          ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum
          dolor sit amet..", comes from a line in section 1.10.32
        </div>

        <ScrollHijacking scrollHijackRef={this.scrollHijackRef} isSticky={isSticky} />
        <div>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
          of classical Latin literature from 45 BC, making it over 2000 years old. Richard
          McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the
          more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the
          cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
          comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
          of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of
          ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum
          dolor sit amet..", comes from a line in section 1.10.32
        </div>
      </div>
    )
  }
}

class ScrollHijacking extends React.Component {
  render() {
    const { scrollHijackRef, isSticky } = this.props
    return (
      <div
        ref={scrollHijackRef}
        className={isSticky ? 'sticky top-0' : 'relative'}
        style={{ height: `${SLIDES.length * 100}vh` }}
      >
        <div className="top-0 absolute">YOUR GOALS</div>
        <div className="relative">
          {SLIDES.map((slide, i) => (
            <div
              key={i}
              className={`vh-100 pv6 ${i > 0 ? 'absolute top-0' : 'static'}`}
              style={{ backgroundColor: slide.backgroundColor }}
            >
              <img src={slide.img} />
              <p dangerouslySetInnerHTML={{ __html: slide.content }} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}
